import React from 'react'
import { useIntl } from 'react-intl'

import { SEO } from '../components'
import RainBird from '../images/nawadnianie/anatomia-systemu-rb-large.jpg'
import Zraszacze from '../images/nawadnianie/zraszacze-wybor-large.jpg'
import Sterowanie from '../images/nawadnianie/sterowanie-rb-large.jpg'

const Page = () => {
  const { formatMessage: f } = useIntl()

  return (
    <>
      <SEO title={f({ id: 'navigation.nawadnianie' })} />
      <section className='py-8 px-4'>
        <div className='container mx-auto'>
          <h1 class='text-lg leading-6 font-medium text-gray-900 mb-4'>Nawadnianie</h1>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            Sercem kżdego ogrodu jest system nawadniający. To on pompuje życiodajną wodę która
            utrzymuje rośliny przy życiu i nadaje im świeżość w upalne dni.
          </p>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            Do przygotowania systemu nawadniania nie potrzeba drogich projektów. Można zacząć od
            prostego szkicu na podstawie któego przygotowywany jest ogóny kosztorys.
          </p>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            Podobnie jak przy zakładaniu trawnika, podczas montażu automatycznego systemu
            nawadniania, należy rozpoznać teren, klasę gleby oraz jej przepuszczalność. Prawidłowe
            zdefiniowanie tych podstawowych parametrów będzie mieć wpływ na określenie wydajności
            systemu, np. zużycie wody oraz energii.
          </p>
          <h2 class='text-lg leading-6 font-medium text-gray-900 mb-4'>Montaż systemu</h2>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            Preferowanym przez nas systemem nawadniającym jest system firmy Rain Bird.
          </p>
          <p className='flex justify-center mb-4'>
            <img src={RainBird} className='w-1/2' />
          </p>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            Sam montaż polega na płytkim zakopaniu systemu na gębokości do 35cm. Nawadnianie można
            zamontować na istniejącym ogrodzie jak i na nowo powstającym.
          </p>
          <h2 class='text-lg leading-6 font-medium text-gray-900 mb-4'>
            Zraszacze i linie kroplujące
          </h2>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            Dobór zraszaczy w dużym stopniu zależy od urozmaicenia ogrodu.
          </p>
          <p className='flex justify-center mb-4'>
            <img src={Zraszacze} className='w-1/2' />
          </p>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            Do nawadniania runa, gołych rabat, żywopłotów, drzew i innych nasadzeń stosujemy linie
            kroplujące.
          </p>
          <h2 class='text-lg leading-6 font-medium text-gray-900 mb-4'>Sterowanie</h2>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            Do sterowania systemem wykorzystujemy kontroler <b>Rain Bird</b> serii ESP-RZX.
            Urządzenie zostało zaprojektowane mając na uwadze łatwość użytkownia.{' '}
            <b>Planowanie strefowe</b> pozwala, aby każdy zawór był uruchamiany niezależnie od
            pozostałych. Sterownik wyposażony jest w duży wyświetlacz LCD, który pokazuje wszystkie
            dostęne progamy, dla wszystkich sterf, na jednym ekranie. Interfejs graficzny jest łatwy
            do zrozumienia a wszystkie funkcje sterowania są na wyciągnięcie ręki.
          </p>
          <p className='flex justify-center mb-4'>
            <img src={Sterowanie} className='w-1/2' />
          </p>
          <table class='table table-bordered text-sm'>
            <thead>
              <tr>
                <th>Opcje sterowania</th>
                <th>Opcje planowania</th>
                <th>Opcje zaawansowane</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width='25%'>
                  <ul>
                    <li>
                      Duży wyświetlacz LCD oraz <strong>łatwy w obsłudze interfejs</strong>{' '}
                      użytkownika
                    </li>
                    <li>Wejście na czujnik pogody</li>
                    <li>Uruchamianie obiegu zaworu głównego/pompy</li>
                    <li>Trwała pamięć programu</li>
                    <li>Programowalny na bateriach</li>
                  </ul>
                </td>
                <td width='50%'>
                  <ul>
                    <li>
                      <strong>Planowanie strefowe</strong> - pozwala na uruchamianie niezależnych
                      harmonogramów nawadniania przypisanych do różych stref (opcje Czas Działania,
                      Godziny Rozpoczęcia oraz Dni Nawadniania mogą być ustawiane dla każdej
                      strefy).
                    </li>
                    <li>6 niezależnych ustawień Godziny Rozpoczęcia dla każdej strefy</li>
                    <li>
                      4 niezależne ustawienia Dni Nawadniania dla każdej strefy: według dni
                      tygodnia, dni parzystych/nieparzystych, cykliczne (co 1 dzień, co 14 dni)
                    </li>
                    <li>
                      <strong>Różne uruchamianie</strong> nawadniania dla wszystkich lub wybranych
                      stref
                    </li>
                  </ul>
                </td>
                <td width='25%'>
                  <ul>
                    <li>Elektroniczny wyłącznik diagnostyczny</li>
                    <li>
                      <strong>Opcja szybkiego programowania</strong> i kopiowania konfiguracji
                      poprzedniej strefy ułatwia szybką konfigurację
                    </li>
                    <li>Omijanie czujnika pogody</li>
                    <li>Omijanie czujnika pogody dla stef</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <h2 class='text-lg leading-6 font-medium text-gray-900 mb-4'>Gwarancja i serwis</h2>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            <b>Gwarancja na montaż</b> i urządzenia trwa 2 lata. Sterownik objęty jest 4-ro letnią
            gwarancją. Serwisujemy w zależności od zgłoszenia.
          </p>
          <p className='text-gray-700 md:text-base text-sm mb-4'>
            Zabezpieczenie przedzimowe wykonujemy za pomocą sprężarki.
          </p>
        </div>
      </section>
    </>
  )
}

export default Page
